import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

export const RedirectWithParams: FC<{ to: string }> = ({ to }) => {
    return (
        <Redirect
            to={{
                pathname: to,
                search: window.location.search,
            }}
        />
    );
};
