import { LogoutUi, LogoutUiProps } from 'components/logout/ui/LogoutUi';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const LogoutPage: React.FC = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const logoutType = (searchParams.get('logout-type') || 'auto') as LogoutUiProps['logoutType'];

    return <LogoutUi logoutType={logoutType} />;
};
